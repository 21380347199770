<template>
  <div class="travelTransactionPage">
    <div class="travelTransactionContainer">
      <img :src="configUrl.bgUrl" alt="" class="downBgImg" mode="widthFix" />
      <div class="btnContaier">
        <div class="androidBtn" @click="goDownAndroidIos"></div>
        <div class="iosBtn" @click="goDownAndroidIos"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadApp } from "@/utils/downloadApp";
import {
  getXbAdUser,
  getUuid,
  getXbDownloadApp,
  getConfig,
} from "@/api/security";
export default {
  name: "TravelTransaction",
  data() {
    return {
      title: "",
      configUrl: {},
      getCookie: "",
    };
  },
  async mounted() {
    this.getCookie = localStorage.getItem("cookie-xbdown");
    if (
      this.getCookie == null ||
      this.getCookie == undefined ||
      this.getCookie == ""
    ) {
      await this.requestGetUuid();
    }
    this.requestGetXbAdUser();
    let configDetail = {
      key: "traveltransaction_downurl",
    };
    this.requestGetConfig(configDetail);
  },
  methods: {
    goDownAndroidIos() {
      let { iosUrl, androidUrl } = this.configUrl;
      downloadApp(
        iosUrl,
        androidUrl,
        this.getCookie,
        this.requestGetXbDownloadApp
      );
    },
    async requestGetConfig(configDetail) {
      try {
        const result = await getConfig(configDetail);
        if (result.code === 20000 || result.status_code === 1000) {
          this.configUrl = JSON.parse(result.data.content);
        } else {
          this.$toast.fail(result.message);
        }
      } catch (error) {
        this.$toast.fail("请求失败");
      }
    },
    async requestGetXbAdUser() {
      let config = {
        uuid: this.getCookie,
      };
      try {
        const result = await getXbAdUser(config);
        if (result.code === 20000 || result.status_code === 1000) {
        } else {
          this.$toast.fail(result.message);
        }
      } catch (error) {
        this.$toast.fail("请求失败");
      }
    },
    async requestGetUuid() {
      try {
        const result = await getUuid();
        if (result.code === 20000 || result.status_code === 1000) {
          localStorage.setItem("cookie-xbdown", result.data);
          this.getCookie = result.data;
        } else {
          this.$toast.fail(result.message);
        }
      } catch (error) {
        this.$toast.fail("请求失败");
      }
    },
    async requestGetXbDownloadApp(config) {
      try {
        const result = await getXbDownloadApp(config);
        if (result.code === 20000 || result.status_code === 1000) {
        } else {
          this.$toast.fail(result.message);
        }
      } catch (error) {
        this.$toast.fail("请求失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.travelTransactionPage {
  .travelTransactionContainer {
    width: 100%;
    height: 812px;
    position: relative;
    .downBgImg {
      width: 100%;
      height: 100%;
    }
    .btnContaier {
      width: 100%;
      position: fixed;
      bottom: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: env(safe-area-inset-bottom);
      .androidBtn {
        width: 155px;
        height: 57px;
        background-image: url("~@/static/images/androidBtn@2x.png");
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        margin-right: 11px;
      }
      .iosBtn {
        width: 155px;
        height: 57px;
        background-image: url("~@/static/images/iosBtn@2x.png");
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>
