export function downloadApp(iosHref, isAndroidHref, uuid, requestGetXbDownloadApp) {
    const u = navigator.userAgent;
    const isWeixin = u.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
    const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1;
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isWeixin) {
        alert("请在浏览器上打开");
    } else {
        //android端
        if (isAndroid) {
            let configAndroid = {
                type: 2,
                uuid,
            };
            requestGetXbDownloadApp(configAndroid);
            window.location.href = isAndroidHref;
        }
        //ios端
        if (isIOS) {
            let configIsIOS = {
                type: 3,
                uuid,
            };
            requestGetXbDownloadApp(configIsIOS);
            window.location.href = iosHref;
        }
    }
}